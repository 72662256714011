.swiper_controller {
    display: flex;
    align-items: center;
    position: absolute;
    bottom: 0;
    left: 0;
}

.swiper-button-prev,
.swiper-button-next {
    flex: 0 0 70 / $setFs + rem;
    width: 70 / $setFs + rem;
    height: 70 / $setFs + rem;
    border-radius: 70 / $setFs + rem;
    border: 1px solid #dfdfdf;
    position: relative;
    left: auto;
    right: auto;
    bottom: auto;
    margin: 0 7px;
    transition: opacity .2s ease-out;
    opacity: .8;
    z-index: 5;
    cursor: pointer;

    &:after {
        font-size: 0;
    }

    &::before {
        content: '';
        width: 22px;
        height: 22px;
        background-image: url("data:img/png;base64,iVBORw0KGgoAAAANSUhEUgAAACwAAAAWCAQAAAARbLZBAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAAmJLR0QA/4ePzL8AAAAHdElNRQfnBR4OFS8pSdgBAAAAlUlEQVQ4y7XVvRVBQRAG0PuIZC98sQaogA7UQA+6UIQadEAFNCAWyiQcK3EcP5uZ+Sa/wezMDrU0VhLSt1bi2YGNEg+3tko83Nk/2VB46PhiA+GR0xsbBk+cP9ggeObyxYbAc9cfNgBeulfYv6unielmLQu3jFaQ9ngwzRk3GOcsCGkrDZ1DDkxrlwOnffSknSZCjukDAUqX+HPXsloAAAAASUVORK5CYII=");
        background-repeat: no-repeat;
        background-position: center center;
        background-size: 22px auto;
        position: absolute;
        top: 50%;
        left: 50%;
        margin-top: -11px;
        margin-left: -11px;
        transition: transform .4s ease-out;
    }

    &:hover {
        opacity: 1;
    }

    &.swiper-button-disabled {
        opacity: .2;
    }
}

.swiper-button-prev {
    &:hover {
        &::before {
            transform: translateX(-5px);
        }
    }
}

.swiper-button-next {
    &::before {
        transform: scaleX(-1);
    }

    &:hover {
        &::before {
            transform: scaleX(-1) translateX(-5px);
        }
    }
}

.swiper-pagination.swiper-pagination-horizontal {
    display: flex;
    justify-content: flex-start;
    left: auto;
    right: auto;
    bottom: auto;
    margin-right: 40px;
    position: absolute;
    top: 100%;
    left: 0;
    margin-top: 12px;
}

.swiper-pagination-bullet {
    width: 24 / $setFs + rem;
    height: 24 / $setFs + rem;
    border-radius: 24 / $setFs + rem;
    border: 0;
    box-sizing: border-box;
    background-color: transparent;
    margin: 0 3 / $setFs + rem !important;
    opacity: 1;
    position: relative;

    &::before,
    &::after {
        content: '';
        width: 100%;
        height: 100%;
        background-image: url('../../img/icon_sprite_6.png');
        background-size: 200% auto;
        position: absolute;
        top: 0;
        left: 0;
        transition: all .3s ease-out;
    }

    &::after {
        background-position: 100% 0;
        opacity: 0;
        z-index: 1;
    }
}

.swiper-pagination-bullet-active {
    background-color: transparent;
    opacity: 1;

    &::after {
        opacity: 1;
    }
}
