.accesskey {
    font-size: 16 / $setFs + rem;
    position: absolute;
    right: 100%;
    top: 0;
    margin-right: 10 / $setFs + rem;
    z-index: 1;
    @include acc;

    @media screen and (max-width: 950px) {
        display: none;
    }
}

.sr-only {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1 / $setFs + rem;
    margin: -1 / $setFs + rem;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1 / $setFs + rem;
}

.title {
    font-size: $size_L;
    font-family: $font1;
    font-weight: normal;
    transform: scaleY(1.15);
    letter-spacing: 3px;
}

.txt {
    font-size: $size_M;
    line-height: (37/18);
}

.pic {
    position: relative;
    overflow: hidden;
}

.img {
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
}

.video {
    width: 100%;
    height: 100%;
    object-fit: contain;
    position: absolute;
    top: 0;
    left: 0;
}

.textarea {
    white-space: pre-wrap;
}

:focus,
button:focus {
    outline: 0;
}

a {
    display: block;
    text-decoration: none;
    transition: all 0.1s ease-out;
    color: inherit;
    box-sizing: border-box;
    position: relative;

    &:hover {
        color: inherit;
        text-decoration: none;
    }
}


.hide {
    display: none;
}

body .pc {
    @media screen and (max-width: 950px) {
        display: none;
    }
}

body .pad {
    display: none;

    @media screen and (max-width: 950px) {
        display: block;
    }
}

body .mobile {
    display: none;

    @media screen and (max-width: 640px) {
        display: block;
    }
}

.clear {
    &:before {
        content: "";
        display: table;
    }

    &:after {
        @extend :before;
        clear: both;
    }
}

.c {
    clear: both;
}

img {
    width: 100%;
    height: auto;
    display: block;
}

iframe {
    width: 100%;
    height: auto;
}