.button_row {
    display: flex;
}

.button_link {
    @include buttonApp(darken($color2, 0%));
    font-weight: bold;
    letter-spacing: 2px;
    height: 43 / $setFs + rem;
    border-radius: 40 / $setFs + rem;
    line-height: 40 / $setFs + rem;
    padding: 0 20 / $setFs + rem;

    &:hover {
        @include buttonApp_hvr(darken($color2, 15%));
    }
}

.btn_row {
    display: flex;
    justify-content: center;
    position: relative;
    z-index: 2;
}


@keyframes angle_right {
    50% {
        transform: translateX(5px);
    }
}

.btn_more {
    position: relative;
    left: -30 / $setFs + rem;
}

.btn_more_link {
    font-size: 24 / $setFs + rem;
    line-height: 2;
    padding: 10 / $setFs + rem 0;
    position: relative;
    display: flex;
    align-items: center;

    &:focus,
    &:hover {
        .btn_more_circle {
            transform: scale(.6);
        }
    }
}

.arrow_right {
    width: 62 / $setFs + rem;
    margin-left: 10 / $setFs + rem;

    &:before {
        content: '';
        border-style: solid;
        border-width: 7 / $setFs + rem 0 7 / $setFs + rem 7 / $setFs + rem;
        border-color: transparent transparent transparent #000;
        position: absolute;
        right: 0;
        top: -5 / $setFs + rem;
    }
}

.btn_more_circle {
    width: 70 / $setFs + rem;
    height: 70 / $setFs + rem;
    border-radius: 70 / $setFs + rem;
    border: 1 / $setFs + rem solid rgba(#000, .2);
    box-sizing: border-box;
    position: absolute;
    top: 50%;
    right: 0;
    margin-top: -35 / $setFs + rem;
    margin-right: -30 / $setFs + rem;
    z-index: -1;
    transition: all .3s ease-out;
}

.btn_more_txt {
    font-family: $font1;
    font-style: italic;
    padding-bottom: 5 / $setFs + rem;
}