@import "../share/variable";
@import "../share/share";
@import "../share/components/swiper";
@import "../share/components/button";


// #scene {
//     height: 100vh;
//     position: relative;
// }

// .layer {
//     width: 100%;
//     height: 100%;
//     position: absolute;
//     top: 0;
//     left: 0;
// }

// .kv_sec {
//     width: 100%;
//     height: 200vh;
//     position: relative;
// }

.kv {
    width: 100%;
    height: 100vh;
    position: relative;
}

.kv_inner {
    width: 100%;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    perspective: 1000 / $setFs + rem;
}

.kv_title_row {
    writing-mode: vertical-rl;
    position: absolute;
    top: 125 / $setFs + rem;
    right: 180 / $setFs + rem;
    z-index: 1;
}

.kv_maintitle {
    font-size: 113 / $setFs + rem;
    font-family: $font1;
    font-weight: normal;
    letter-spacing: 19 / $setFs + rem;
}

.kv_subtitle {
    font-size: 55 / $setFs + rem;
    font-family: $font1;
    font-weight: normal;
    letter-spacing: 10 / $setFs + rem;
    padding-top: 140 / $setFs + rem;
    position: relative;

    b {
        font-weight: normal;
        display: block;
    }

    span {
        font-size: 27 / $setFs + rem;
        letter-spacing: 2 / $setFs + rem;
        padding-bottom: 15 / $setFs + rem;
        position: absolute;
        bottom: 0;
        right: 100%;
        margin-right: 10 / $setFs + rem;
    }
}

.kv_line {
    width: 3 / $setFs + rem;
    position: absolute;
    bottom: 100%;
    left: 50%;
    margin-left: -1 / $setFs + rem;
    margin-bottom: 5 / $setFs + rem;
}

.kv_notebook {
    width: 1961 / 1920 * 100%;
    position: absolute;
    top: 0.6%;
    left: 50%;
    // transform: translateX(-43.2%) translateY(0%) rotate3d(1, 0, 0, 0deg);
    transform: translateX(-53%) translateY(34.5%) rotate3d(1, 0, 0, 83deg);

    // &.from {
    //     transform: translateX(-53%) translateY(34.5%) rotate3d(1, 0, 0, 83deg);
    // }
}

.kv_latlng {
    position: absolute;

    &.latlng_1 {
        width: 73 / 1920 * 100%;
        top: 8.3%;
        left: 57.75%;
    }

    &.latlng_2 {
        width: 81 / 1920 * 100%;
        top: 42.3%;
        left: 7.4%;
    }
}

.kv_mountain {
    width: 876 / 1920 * 100%;
    position: absolute;
    bottom: 9.3%;
    left: 33.7%;
    // left: 50%;
    // transform: translateX(-52%);
}

.kv_mountain_shadow {
    width: 1155 / 876 * 100%;
    position: absolute;
    top: 85%;
    right: 0%;
}

// .kv_mountain_1 {
//     transform: scale(.37) translateX(27%) translateY(-110%);
// }

// .kv_mountain_2 {
//     transform: scale(1.11) translateX(3.6%) translateY(-31%);
// }
.kv_mountain_1 {
    position: relative;
    z-index: 1;
}

.kv_mountain_2 {
    width: 903 / 876 * 100%;
    position: absolute;
    top: 8%;
    right: 49.4%;
}

.kv_ruler {
    width: 431 / $setFs + rem;
    position: absolute;
    top: 36 / $setFs + rem;
    left: 215 / $setFs + rem;
}

.conotent_instruction {
    position: absolute;
    bottom: 15 / $setFs + rem;
    left: 50%;
    transform: translateX(-50%);

    .instruction_arrow {
        margin-top: 15 / $setFs + rem;
        animation: instruction_arrow 1.3s ease-out infinite;
    }

    .instruction_txt {
        text-align: center;
    }
}

@keyframes instruction_arrow {
    50% {
        transform: translateY(#{10 / $setFs + rem});
    }
}

.ind_about_sec {
    padding-bottom: 100 / $setFs + rem;
    // transform: translateY(100%);

    .bg {
        // top: 200 / $setFs + rem;
        z-index: 1;
    }
}

.ind_about_bg {
    width: 1809 / 1920 * 100%;
    position: relative;
    left: 110 / $setFs + rem;
}

.ind_about_content {
    width: 700 / $setFs + rem;
    // margin-top: -300 / $setFs + rem;
    // margin-left: auto;
    // margin-right: 200 / $setFs + rem;
    transform: translateY(20vh);
    opacity: 0;
    position: relative;
    // top: 44.5%;
    // top: 35.5%;
    left: 18%;
    margin-top: -550 / $setFs + rem;
}

.ind_intro_title_row,
.ind_about_title_row {
    display: flex;
    position: relative;
    left: -5 / $setFs + rem;
    margin-bottom: 35 / $setFs + rem;
}

.ind_intro_title_row {
    left: -33 / $setFs + rem;
}

.ind_intro_title_inner,
.ind_about_title_inner {
    position: relative;
    padding: 28 / $setFs + rem 85 / $setFs + rem 20 / $setFs + rem 70 / $setFs + rem;
}

.ind_intro_title,
.ind_about_title {
    font-family: $font1;
    font-size: 54 / $setFs + rem;
    font-weight: normal;
    transform: scaleY(1.15);
    letter-spacing: 6 / $setFs + rem;
    position: relative;
}

.title_bg {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
}

.ind_about_scrollbox {
    height: 564 / $setFs + rem;
    // overflow-y: scroll;
    overflow-y: hidden;
    padding-left: 10 / $setFs + rem;
    padding-right: 25 / $setFs + rem;

    // &::-webkit-scrollbar {
    //     width: 5 / $setFs + rem;
    // }

    // &::-webkit-scrollbar-track {
    //     border-radius: 5 / $setFs + rem;
    //     background: $txtColor;
    // }

    // &::-webkit-scrollbar-thumb {
    //     border: 1 / $setFs + rem solid $txtColor;
    //     border-radius: 5 / $setFs + rem;
    //     background: $color;
    //     position: relative;
    // }
}

.ind_intro_txt,
.ind_about_txt {
    @extend .txt;
}

.ind_intro_sec {
    margin-top: -15vh;
    z-index: 5;

    .bg {
        transform: translate(0px, 0px);
    }

    .instruction {
        bottom: 50 / $setFs + rem;
    }
}

.ind_intro_bg {
    width: 1809 / 1920 * 100%;
    position: relative;
    left: 110 / 1920 * 100%;
}

.ind_intro_box {
    width: 63%;
    // position: absolute;
    position: relative;
    right: -2.5%;
    z-index: 1;
    margin-left: auto;
    padding-top: 17 / $setFs + rem;
}

.intro_peo_name {
    width: 6.2%;
    position: absolute;
    top: 11%;
    left: 25%;
}

.intro_content {
    width: 620 / $setFs + rem;
    letter-spacing: 1.5 / $setFs + rem;
    position: absolute;
    top: 39%;
    right: 88%;

    .ind_about_txt {
        position: relative;
        left: -13 / $setFs + rem;
    }
}

.intro_content_bg {
    width: 500 / $setFs + rem;
    position: absolute;
    top: -50 / $setFs + rem;
    right: -50 / $setFs + rem;
    z-index: -1;
}

.intro_duration {
    font-size: 26 / $setFs + rem;
    font-family: $font1;
    letter-spacing: 4 / $setFs + rem;
    text-indent: 4 / $setFs + rem;
    line-height: 1;
    margin-bottom: -6 / $setFs + rem;
    margin-left: 16 / $setFs + rem;
}

.anchor_box {
    position: fixed;
    top: 50%;
    left: 67 / $setFs + rem;
    z-index: 50;
    transform: translateY(-45%);
    // transition: transform .4s ease-out;

    // &.active {
    //     transform: translateX(0) translateY(-45%);
    // }
}

.anchor_li {
    // padding: 23 / $setFs + rem 0;
    padding: 15 / $setFs + rem 0;
    position: relative;

    &::after {
        content: '';
        width: 1 / $setFs + rem;
        height: 40 / $setFs + rem;
        background-color: $txtColor;
        position: absolute;
        top: 100%;
        left: 50%;
        margin-left: -1 / $setFs + rem;
        transform: translateY(-50%);
    }

    &:last-child {
        &::after {
            display: none;
        }
    }
}

.anchor_link {

    &.active,
    &:hover {
        .anchor_point {
            &:before {
                opacity: 0;
            }

            &:after {
                opacity: 1;
            }
        }
    }
}

.anchor_row {
    position: relative;
}

.anchor_point {
    width: 30 / $setFs + rem;
    height: 30 / $setFs + rem;
    position: relative;

    &:before,
    &:after {
        content: '';
        width: 100%;
        height: 100%;
        background-image: url('../../img/icon_sprite_6.png');
        background-size: 200% auto;
        position: absolute;
        top: 0;
        left: 0;
        transition: opacity .1s ease-out;
    }

    &:after {
        background-position: 100% 0;
        opacity: 0;
    }
}

.anchor_txt {
    white-space: nowrap;
    position: absolute;
    top: 50%;
    left: 100%;
    margin-left: 10 / $setFs + rem;
    transform: translateY(-50%);
}

.anchor_year {
    font-size: 20 / $setFs + rem;
    font-family: $font1;
    letter-spacing: 2 / $setFs + rem;
}

.anchor_title {
    font-size: 12 / $setFs + rem;
    font-family: $font1;
    letter-spacing: 1 / $setFs + rem;
}

.ind_chronology_sec {
    .bg {
        opacity: .6;
    }
}

.ind_chronology_bg {
    width: 92.6%;
    height: 100%;
    background-image: url('../../img/ind_chronology_bg.png');
    background-position: center top;
    background-size: 100% auto;
    background-repeat: repeat-y;
    position: absolute;
    top: 0;
    left: 10%;
    mask-image: linear-gradient(180deg, black 0%, transparent 0%);
    // &::before {
    //     content: '';
    //     width: 100%;
    //     height: 100vh;

    //     mask-repeat: repeat-x;
    //     mask-size: auto 100%;
    //     // background-image: url('../../img/mask_gradient.png');
    //     // background-attachment: fixed;
    //     // background-position: center top;
    //     // background-size: auto 100%;
    //     // background-repeat: repeat-x;
    //     position: absolute;
    //     top: 0;
    //     left: 0;
    // }
}

.item_row {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 205 / $setFs + rem;
    padding-bottom: 200 / $setFs + rem;
}

.pic_swiper {
    width: 610px;
    position: absolute;
    opacity: 0;
    // margin-top: -15px;
    // margin-left: -15px;
    // margin-right: -15px;

    .swiper-slide {
        padding: 15px;
        box-sizing: border-box;
    }

    img {
        height: 387px;
        object-fit: contain;
        object-position: center center;
        filter: drop-shadow(2px 3px 7px rgba(#000, .3));
    }
}

.item_inner_y {
    opacity: 0.5;
    transform: translateY(300px);
    transform-origin: center bottom;
}

.item_inner_scale {
    transform: scale(1.5);
}

.item_inner_blur {
    filter: blur(10px);
}

.item {
    position: relative;

    &:nth-child(4n+1) {
        left: -110 / $setFs + rem;
        margin-bottom: 190 / $setFs + rem;
        transform-origin: right bottom;

        .pic_swiper {
            top: -150%;
            right: 200%;
            transform: translateX(-100px);
        }

        .swiper-pagination.swiper-pagination-horizontal {
            justify-content: flex-end;
        }

        img {
            object-position: right center;
        }
    }

    &:nth-child(4n+2) {
        left: 230 / $setFs + rem;
        margin-bottom: 190 / $setFs + rem;
        transform-origin: left bottom;

        .pic_swiper {
            bottom: 90%;
            left: 128%;
            transform: translateX(100px);
        }

        img {
            object-position: left center;
        }
    }

    &:nth-child(4n+3) {
        left: -183 / $setFs + rem;
        margin-bottom: 295 / $setFs + rem;
        transform-origin: right bottom;

        .pic_swiper {
            top: -172%;
            right: 155%;
            transform: translateX(-100px);
        }

        .swiper-pagination.swiper-pagination-horizontal {
            justify-content: flex-end;
        }

        img {
            object-position: right center;
        }
    }

    &:nth-child(4n+4) {
        left: 230 / $setFs + rem;
        // margin-bottom: 430 / $setFs + rem;
        margin-bottom: 312 / $setFs + rem;
        transform-origin: left bottom;

        .pic_swiper {
            bottom: 90%;
            left: 128%;
            transform: translateX(100px);
        }

        img {
            object-position: left center;
        }
    }

    &.anchor_3 {
        margin-bottom: 430 / $setFs + rem;
    }

    &.anchor_4 {
        left: -445 / $setFs + rem;
        margin-bottom: 75 / $setFs + rem;

        .pic_swiper {
            width: 1022px;
            right: -278%;
            top: auto;
            bottom: 104%;
        }
    }

    &.anchor_7 {
        .pic_swiper {
            width: 860px;
            left: -38%;
            bottom: 71%;
        }
    }

    &.anchor_8 {
        .pic_swiper {
            width: 595px;
            top: -645%;
            right: 152%;

            img {
                height: 887px;
            }
        }
    }

    &.anchor_15 {
        left: 80 / $setFs + rem;
        margin-bottom: 312 / $setFs + rem;

        .pic_swiper {
            width: 490px;
            left: 290%;
            bottom: -570%;

            img {
                height: 1010px;
            }
        }
    }

    &.anchor_18 {

        .pic_swiper {
            top: -400%;

            img {
                height: 1010px;
            }
        }
    }
}

.item_year {
    font-size: 57 / $setFs + rem;
    font-family: $font1;
    letter-spacing: 11 / $setFs + rem;
    margin-bottom: 8 / $setFs + rem;
}

.item_txt {
    white-space: nowrap;
    font-size: 18 / $setFs + rem;
    line-height: 2;
    letter-spacing: 1.5 / $setFs + rem;
    position: absolute;
    top: 100%;
    left: 0;
    white-space: pre;
}

.item_flag {
    width: 28 / $setFs + rem;
    position: absolute;
    top: 20 / $setFs + rem;
    right: 100%;
    margin-right: 5 / $setFs + rem;
}


// .item_pic {
//     position: absolute;
//     top: 0;
//     right: 100%;

//     &.pic_1907 {
//         width: 534 / $setFs + rem;
//         top: -173%;
//         right: 193%;
//     }

//     &.pic_1918 {
//         width: 810 / $setFs + rem;
//         top: -33%;
//         right: 30%;
//     }

//     &.pic_1927 {
//         width: 772 / $setFs + rem;
//         top: -268%;
//         right: 100%;
//     }

//     &.pic_1938 {
//         width: 571 / $setFs + rem;
//         top: -127%;
//         right: 148%;
//     }
// }